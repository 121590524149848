import React from 'react'
import './ContactUsInfo.css'
import Question from '../../Assets/question.png'
import Email from '../../Assets/email.png'
import Whatsapp from '../../Assets/whatsapp.png'
import School from '../../Assets/school.png'

const ContactUsInfo = () => {
  return (
    <div className='ksvesity__contactusinfo'>
        <div className='ksvesity__contactusinfo-container'>
            <h2>We’re here to help.</h2>
            <p>Visit our FAQ page to find answers, shoot us an email, send us a WhatsApp message, or visit one of our physical locations.</p>
            <div className='ksvesity__contactusinfo-container_info'>
                <div className='ksvesity__contactusinfo-container_info-div1'>
                    <img src={Question} alt='Question'/>
                    <h2>Visit FAQ page</h2>
                    <p>Most of your pressing questions, already been answered.</p>
                </div>
                <div className='ksvesity__contactusinfo-container_info-div2'>
                    <img src={Email} alt= 'Email'/>
                    <h2>Send an email</h2>
                    <p>Expect a response from one of our staff within 2 to 3 hours</p>
                </div>
                <div className='ksvesity__contactusinfo-container_info-div3'>
                    <img src={Whatsapp} alt='whatsapp'/>
                    <h2>Hello! WhatsApp</h2>
                    <p>You can connect with us if you are already on WhatsApp.</p>
                </div>
                <div className='ksvesity__contactusinfo-container_info-div4'>
                    <img src={School} alt='School'/>
                    <h2>Visit our School</h2>
                    <p>No 42, Montgomery Road, Yaba Lagos, Nigeria.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactUsInfo