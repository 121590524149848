import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='ksvesity__footer' id='about-us'>
            <div className='ksvesity__footer-container'>
                <div className='ksvesity__footer-container_div1'>
                    <div className='ksvesity__footer-container_div1-item1'>
                        <Link to='/contactus'><button className='ksvesity__navbar-container_contact-button'>Contact Us</button></Link>
                    </div>
                    <div className='ksvesity__footer-container_div1-item2'>
                        <h2>Resources</h2>
                        <ul>
                            <li>Terms & Conditions</li>
                            <li>Privacy Policy</li>
                            <li>About Us</li>
                        </ul>
                    </div>
                    <div className='ksvesity__footer-container_div1-item3'>
                        <h2>Explore</h2>
                        <ul>
                            <li>Python For Finance</li>
                            <li>Technology</li>
                            <li>Fintech</li>
                        </ul>
                    </div>
                    <div className='ksvesity__footer-container_div1-item4'>
                        <h2>Address</h2>
                        <p>Vatebra Tech Hub, Royal Office Building, 4th Floor, KM 25 Lekki-Epe Expressway</p>
                        <p>+2348166885313</p>
                        <p>info@ksversity.com</p>
                    </div>
                </div>
                <div className='ksvesity__footer-container_div2'>
                    <p>{currentYear} KSV</p>
                    <div className='ksvesity__footer-container_div2-div'>
                        <p className='first-p'>Privacy Policy</p>
                        <div />
                        <p>Terms & Conditions</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
