import { Card, Button,} from 'react-bootstrap'
function Course({ image, author, title, description, lessons, students }) {
  return (
    <Card className='mb-3 flex-fill' style={{ height: '100%' }}>
      <Card.Img variant='top' src={image} alt='COURSE' />
      <Card.Body className='d-flex flex-column'>
        <Card.Title style={{fontWeight: '700', fontSize: '24px'}}>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        {lessons && <Card.Text>{lessons} lessons</Card.Text>}
        <Card.Text>{students} Students</Card.Text>
        {author && <Card.Text>Author: {author}</Card.Text>}
        <Button variant='primary'>EXPLORE</Button>
      </Card.Body>
    </Card>
  );
}

export default Course