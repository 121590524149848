import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Course from '../../section3/Course'
import Course1 from '../../Assets/kidzone1.jpg.png';
import Course2 from '../../Assets/kidzone2.jpg.png';
import Course3 from '../../Assets/kidzone3.jpg.png';

const KidZoneSection = () => {
    const courses = [
        {
          image: Course1,
          title: 'Data Literacy Beginner',
          description: "Understanding and applying the fundamental features of python programming while building fun, interactive projects in Python.You’ll learn how to work with variables, data types and functions, all while building a passion for programming through our engaging project-practical skills",
          students: 12
        },
        {
          image: Course2,
          title: 'Web Development',
          description: "Understanding and applying the fundamental features of data science and the science of prediction. You’ll learn how to work with variables, data types, loops and functions, all while building a passion for the science of prediction.",
          students: 15
        },
        {
          image: Course3,
          title: 'UI/UX ',
          description: "Understanding and awareness of data literacy and exposure the programming basics, statistics and AI. You’ll learn how to work with datasets, mean, median, mode and Excel, all while building a passion for the science of prediction.",
          students: 12
        }
      ];
    
      return (
        <div style={{backgroundColor: '#221638', paddingBottom: '.5rem'}}>

            <Container lg>
                <div>
                    <h2 className='display-4 text-center ' style={{ color:'white', fontFamily: 'Nunito', fontSize: '36px', paddingTop: '90px'}} >BEGINNER COURSES</h2>
                    <p className='lead text-center ' style={{color:'white', marginBottom: '50px' }}>
                        Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it! 
                    </p>
                </div>
                <Row className='d-flex align-items-stretch'>
                    {courses.map((course, index) => (
                    <Col md={4} key={index} className='mb-3'>
                        <Course
                        image={course.image}
                        author={course.author}
                        title={course.title}
                        description={course.description}
                        lessons={course.lessons}
                        students={course.students}
                        />
                    </Col>
                    ))}
                </Row>
                <Row className='d-flex align-items-stretch' style={{marginTop: '50px'}}>
                    {courses.map((course, index) => (
                    <Col md={4} key={index} className='mb-3'>
                        <Course
                        image={course.image}
                        author={course.author}
                        title={course.title}
                        description={course.description}
                        lessons={course.lessons}
                        students={course.students}
                        />
                    </Col>
                    ))}
                </Row>
                <p className='text-center ' style={{color: 'white', marginTop: '60px', paddingBottom: '60px'}}>
                Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that. <span style={{fontWeight: '800'}}>Register Free Now!.</span>
                </p>
            </Container>
        </div>
      );
}

export default KidZoneSection