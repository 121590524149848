import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import AllCourses from './AllCourses/AllCourses';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MachineLearning from './Pages/MachineLearning/MachineLearning';
import AllCourses from './Pages/AllCourses/AllCourses';
import Mql5 from './Pages/Mql5/Mql5';
import PythonFinance from './Pages/PythonFinance/PythonFinance';
import PythonData from './Pages/PythonData/PythonData';
import CryptoTrading from './Pages/CryptoTrading/CryptoTrading';
import Kidzonepage from './Pages/Kidzonepage/Kidzonepage';
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Pages/ContactUs/ContactUs';
import CurrencyTrading from './Pages/CurrencyTrading/CurrencyTrading';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/all-courses",
    element: <AllCourses />,
  },
  {
    path: "/machinelearning",
    element: <MachineLearning />,
  },
  {
    path: "/mql5",
    element: <Mql5 />,
  },
  {
    path: "/pythonfinance",
    element: <PythonFinance />,
  },
  {
    path: "/Pythondata",
    element: <PythonData />,
  },
  {
    path: "/cryptotrading",
    element: <CryptoTrading />,
  },
  {
    path: "/currencytrading",
    element: <CurrencyTrading/>,
  },
  {
    path: "/kidzonepage",
    element: <Kidzonepage />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },

]); 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
