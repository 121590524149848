import React from 'react'
import './Expectation.css'
import Teachers from '../../Assets/teachers.png'
import Handson from '../../Assets/Handson.png'
import Certificate from '../../Assets/certificate.png'
import Support from '../../Assets/support.png'
import Conducive from '../../Assets/conducive.png'
// import Refreshment from '../../Assets/refreshment.png'
import Souvenir from '../../Assets/souvenir.png'

const Expectation = () => {
    const Data = [
        {
          title: 'Experienced Teachers',
          text: 'You’ll be taught and mentored by some of the best teachers in the industry.',
          id: 1,
          image: Teachers
        },
        {
          title: 'Hands-on',
          text: 'Quickly move past theories, get hands-on with our project-based programs',
          id: 2,
          image: Handson
        },
        {
          title: 'Certificate',
          text: 'Get your very own physical certificate to prove your participation',
          id: 3,
          image: Certificate
        },
        {
          title: 'Support System',
          text: 'Become part of an ever-growing, supportive community of like minds',
          id: 4,
          image: Support
        },
        {
          title: 'Conducive environment',
          text: 'Our classes and the environment is serene and conducive for learning.',
          id: 5,
          image: Conducive  
        },
        {
          title: 'Refreshments',
          text: 'We provide light refreshments for every class',
          id: 6,
          image: Souvenir  
        },
        {
          title: 'Souvenirs',
          text: 'You will get a free t-shirt, jotter, pen etc. This is to make you feel right at home, cause why not?!',
          id: 7,
          image: Souvenir
        },
      ];

  return (
    <div className='ksvesity__expectation'>
      <div className='ksvesity__expectation-container'>
        <h1>Come with expectations</h1>
        <div className='ksvesity__expectation-container-div'>
            {Data.map((item)=>{
                return(
                    <div className='items' key={item.id}>
                        <img src={item.image} alt='banner'/>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  )
}

export default Expectation
