import React from 'react'
import './AboutUs.css'
import Navbar from '../../Navbar/Navbar'
import KidZoneEnroll from '../../PagesComponents/KidZoneEnroll/KidZoneEnroll'
import Div from '../../Assets/div.png'
import AboutUsMission from '../../PagesComponents/AboutUsMission/AboutUsMission'
import AboutUsVolunteer from '../../PagesComponents/AboutUsVolunteer/AboutUsVolunteer'
import Footer from '../../Footer/Footer'


const data = 
  {
    image: Div,
    H4: 'WHAT WE DO',
    H2: 'Educating Talents and Building a More Inclusive Future of Work.',
    P: 'We are an Edinburgh based Social Enterprise Founded in May 2019 with the aim to closing both the data divide and attainment gap in Scotland. We are working to change the face of Scotland’s future talent pool by increasing the representation of the disadvatanged groups in the data economy.',
    item1 : 'Data Literacy',
    item2: 'Data Science',
    item3: 'Web Development',
    item4: 'Finance ',

  }

const AboutUs = () => {
  return (
    <div>
       <Navbar />
       <div>
        <KidZoneEnroll {...data}/>
        <AboutUsMission />
        <AboutUsVolunteer />
        <Footer />
       </div>
    </div>
  )
}

export default AboutUs