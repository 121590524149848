import React from 'react'
import './Kidzone.css'
import icon from '../Assets/Vector.png'
import Girl from '../Assets/little-girl.png'
import { Link } from 'react-router-dom'



const Kidzone = () => {
  return (
    <div className='ksvesity__kidzone ' id='kids-zone'>
        <div className='ksvesity__kidzone-container'>
            <div className='ksvesity__kidzone-container_item1'>
                <div className='ksvesity__kidzone-container_item1-info'>
                    <h4>KIDS ZONE</h4>
                    <h2 >Unlock the Power of Data and Finance for Kids: Learn and Grow with Expert Guidance, Anytime, Anywhere.</h2>
                    <p >Unlock the World of Data for Kids: Empower Young Minds with Fun and Engaging Online Learning, Sparking Curiosity and Building a Strong Foundation for Future Success!</p>
                    <div className='ksvesity__kidzone-container_item1-info-about'>
                        <div className='item'>
                            <img src={icon} alt='icon'/>
                            <h3>Skilled Trainers</h3>
                        </div>
                        <div className='item'>
                            <img src={icon} alt='icon'/>
                            <h3>Affordable Courses</h3>
                        </div>
                        <div className='item'>
                            <img src={icon} alt='icon'/>
                            <h3>Efficient & Flexible</h3>
                        </div>
                        <div className='item'>
                            <img src={icon} alt='icon'/>
                            <h3>Lifetime Access</h3>
                        </div>
                    </div>
                    <Link to='/kidzonepage'><button className='ksvesity__kidzone-container_item1-info-button'>KID ZONE</button></Link>
                </div>
                <div className='ksvesity__kidzone-container_item1-image'>
                    <img src={Girl} alt='GIRL' />
                </div>
            </div>
            </div>
    </div>
  )
}

export default Kidzone
