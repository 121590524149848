import React from 'react'
import './AboutUsMission.css'
import Mission from '../../Assets/Mask group.png'

const AboutUsMission = () => {
  return (
    <div className='ksvesity__aboutusmission'>
        <div className='ksvesity__aboutusmission-container'>
            <div className='ksvesity__aboutusmission-container-info'>
                <h2>Our Mission and Vision</h2>
                <p>To build a literate community and promote self sufficiency by providing learner centred training to young people and adults in data literacy and analytics skills. To work together towards the aim of empowering communities with data literacy and analytics skills.</p>
            </div>
            <div className='ksvesity__aboutusmission-container-image'>
                <img src={Mission} alt='Mission'/>
            </div>
        </div>
    </div>
  )
}

export default AboutUsMission