import React from 'react'
import './KidZoneBanner.css'
import BannerImg from '../../Assets/kidzonee.png'

const KidZoneBanner = () => {
  return (
    <div className='ksvesity__kidzonebanner'>
        <div className='ksvesity__kidzonebanner-container'>
            <div className='ksvesity__kidzonebanner-container_details'>
                <h1>Tech Skills for Everyone</h1>
                <p>We empower people with tech skills and future‑proof our community in a fast‑moving world.</p>
                <button className='ksvesity__kidzonebanner-container_details-button'>View  courses</button>
            </div>
            <div className='ksvesity__kidzonebanner-container_image'>
                <img src={BannerImg} alt='item'/>
            </div>
        </div>
    </div>
  )
}

export default KidZoneBanner