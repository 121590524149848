import React from 'react'
import './KidZoneBanner2.css'
import Kidboy from '../../Assets/kidboy.jpg.png'
import Kidgirl from '../../Assets/kidgirl.jpg.png'
import Kidcalendar from '../../Assets/kidcalendar.jpg.png'
import kidboyyyy from '../../Assets/kidboyyy.png'
import vector from '../../Assets/Vectorr.png'
import calendar from '../../Assets/calendar.png'

const KidZoneBanner2 = () => {
  return (
    <div className='ksvesity__kidzonebanner2'>
        <div className='ksvesity__kidzonebanner2-container'>
            <div className='ksvesity__kidzonebanner2-container-div1'>
                <img className= 'image1' src={Kidboy} alt='kidboy' />
                <div className='ksvesity__kidzonebanner2-container-div1_div'>
                    <img src={kidboyyyy}  alt= 'kidboyyyy'/>
                    <h3>CONDUCIVE ENVIRONMENT</h3>
                </div>
            </div>
            <div className='ksvesity__kidzonebanner2-container-div2'>
                <img className= 'image2' src={Kidgirl} alt='kidgirl' />
                <div className='ksvesity__kidzonebanner2-container-div2_div'> 
                    <img src={vector}  alt= 'vector'/>
                    <h3>LEARN FROM EXPERT</h3>
                </div>
            </div>
            <div className='ksvesity__kidzonebanner2-container-div3'>
                <img className= 'image3' src={Kidcalendar} alt='kidcalendar' />
                <div className='ksvesity__kidzonebanner2-container-div3_div'>
                    <img src={calendar}  alt= 'calendar'/>
                    <h3>FLEXIBLE CALENDAR</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default KidZoneBanner2