import React, { useState } from 'react';
import './Faq.css';
import { MdKeyboardArrowDown } from 'react-icons/md';

const Faq = () => {
  const faqData = [
    {
      title: 'Are the programs on-site or online?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 1,
      click: 'click here'
    },
    {
      title: 'What is the duration for a typical program?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 2,
      click: 'click here'
    },
    {
      title: 'Where are you located?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 3,
      click: 'click here'
    },
    {
      title: 'Do you have Evening / Part-time classes?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 4,
      click: 'click here'
    },
    {
      title: 'What is the difference between Weekday and Weekend classes?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 5,
      click: 'click here'
    },
    {
      title: 'Can I join a waiting list for an upcoming cohort?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 6,
      click: 'click here'
    },
    {
      title: 'When will the next session take place?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 7,
      click: 'click here'
    },
    {
      title: 'Do I need a laptop?',
      text: 'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
      id: 8,
      click: 'click here'
    }
  ];

  const [data, setData] = useState(faqData);

  const openAnswer = (id) => {
    setData(prevState =>
      prevState.map(faq =>
        faq.id === id ? { ...faq, on: !faq.on } : faq
      )
    );
  };

  return (
    <div className='ksvesity__faq'>
      <div className='ksvesity__faq-container'>
        <h1>FAQs</h1>
        {data.map(item => (
          <div className='ksvesity__faq-container-div' key={item.id}>
            <div className='ksvesity__faq-container-div_item-faq'>
              <h3 style={{ color: item.on ? 'blue' : 'black' }}>{item.title}</h3>
              <p className={item.on ? 'ksvesity__faq-container-div_item-faq-p_active' : 'ksvesity__faq-container-div_item-faq-p'}>
                {item.text} <span>{item.click}</span>
              </p>
            </div>
            <div className='ksvesity__faq-container-div_item-icon'>
              <MdKeyboardArrowDown onClick={() => openAnswer(item.id)} style={{ color: item.on ? 'blue' : 'black' }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;











































































