import React from "react";
// import { TypeAnimation } from 'react-type-animation';
import bean from "../Assets/banner-shape3.png.png";
import loading from "../Assets/loading.png";
// import team from "../Assets/head.jpg";
import Textra from "react-textra";
import "./Banner.css";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="ksvesity__Banner landing">
      <div className="ksvesity__Banner-container">
        <div className="ksvesity__Banner-container_details">
          <img src={bean} alt="BEAN" />
          <h1 className="mb-5">
            Transform Your Future Become a skilled <br />
            <span className="software">
              <Textra
                effect="downTop"
                data={[
                  "Fintech Engineer",
                  "Python Financial Analyst",
                  "Algorithmic Trader",
                  "MQL5 Algo Trader",
                  "Machine Learning Engineer",
                ]}
                stopduartion={5000}
              />
            </span>
          </h1>
          <p>
            Master the Art of Finance, Empowering You for Today's Global Market.
          </p>
          <a href="#j">
            <button>
              <Link to="/all-courses">View All Courses</Link>
            </button>
          </a>
          <img src={loading} alt="LOADING" />
        </div>
        {/* <div className="ksvesity__Banner-container_image">
          <img src={team} alt="TEAM" className="section__image" />
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
