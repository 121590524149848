import React from 'react'
import './CourseSummary.css'
import Time from '../../Assets/time.png' 
import Duration from '../../Assets/date.png' 
import Beginner from '../../Assets/beginner.png' 
import Laptop from '../../Assets/laptop.png' 
import Project from '../../Assets/project.png' 
import Cost from '../../Assets/cost.png' 

const CourseSummary = () => {
  return (
    <div className='ksvesity__coursesummary'>
        <div className='ksvesity__coursesummary_container'>
            <div className='ksvesity__coursesummary_container-div1'>
                <img src={Time}  alt='time' />
                <h4>Duration</h4>
                <h2>6 Months<br></br><span>(4x’s a week)</span></h2>
            </div>
            <div className='ksvesity__coursesummary_container-div2'>
                <img src={Duration}  alt='duration' />
                <h4>Start Date</h4>
                <h2>February 27th, 2023</h2>
            </div>
            <div className='ksvesity__coursesummary_container-div3'>
                <img src={Beginner}  alt='Beginner' />
                <h4>Level</h4>
                <h2>Beginner</h2>
            </div>
            <div className='ksvesity__coursesummary_container-div4'>
                <img src={Laptop}  alt='Laptop' />
                <h4>Prerequisites</h4>
                <h2>A Laptop</h2>
            </div>
            <div className='ksvesity__coursesummary_container-div5'>
                <img src={Project}  alt='Project' />
                <h4>Projects</h4>
                <h2>3 Projects</h2>
            </div>
            <div className='ksvesity__coursesummary_container-div6'>
                <img src={Cost}  alt='Cost' />
                <h4>Cost</h4>
                <h2>N 1,000,000</h2>
            </div>

        </div>
    </div>
  )
}

export default CourseSummary