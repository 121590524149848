import React from 'react'
import './PagesBanner.css'

const PagesBanner = ({title,text,id,image}) => {
  return (
    <div className='ksvesity__pagesbanner'>
        <div className='ksvesity__pagesbanner-container'>
            <div className='ksvesity__pagesbanner-container_details'>
                <h1>{title}</h1>
                <p>{text}</p>
                <div className='ksvesity__pagesbanner-container_details-buttons'>
                  <button className='apply'>Apply Now</button>
                  <button className='download'>Download Syllabus</button>
                </div>
                <p>Applications are currrently open</p>
            </div>
            <div className='ksvesity__pagesbanner-container_image'>
              <img src={image} alt='banner'/>
            </div>
        </div>
    </div>
  )
}

export default PagesBanner
