import React from 'react'
import './ContactUsEnroll.css'

const ContactUsEnroll = () => {
  return (
    <div className='ksvesity__contactusenroll'>
      <div className='ksvesity__contactusenroll-container'>
        <h2>Ready to transform your career?</h2>
        <button>Enroll Now</button>
      </div>
    </div>
  )
}

export default ContactUsEnroll