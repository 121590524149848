import React from 'react'
import Python from '../../Assets/python.png'
import UI from '../../Assets/uiux.png'
import Datascience from '../../Assets/datascience.png'
import icon1 from '../../Assets/icon-1.png'
import icon2 from '../../Assets/icon-2.png'
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import './AllCourses.css'
import {Link} from 'react-router-dom'


const courses = [
  {
    image: Python,
    title:'Machine Learning for Finance',
    text: 'Build and deploy robust web applications and apps using Django. Using Django’s security implications to create safe web applications with it.',
    id: 1,
    img1: icon1,
    img2: icon2,
    text1: '3 Months',
    text2: 'Twice a week',
    path: "/machinelearning",

  },
  {
    image: UI,
    title:'MQL5 Programming',
    text: 'User Interface(UI) and User Experience(UX) design are in great demand and are applicable across products and industry domains. ',
    id: 2,
    img1: icon1,
    img2: icon2,
    text1: '3 Months',
    text2: 'Twice a week',
    path: "/mql5",

  },
  {
    image: Datascience,
    title:'Python for finance',
    text: 'Python is one of the most popular programming languages for high-level data processing, due to its simple syntax, easy readability, and easy comprehension. ',
    id: 3,
    img1: icon1,
    img2: icon2,
    text1: '3 Months',
    text2: 'Twice a week',
    path: "/pythonfinance",

  },
  {
    image: Datascience,
    title:'Python for Data Analysis',
    text: 'Python is one of the most popular programming languages for high-level data processing, due to its simple syntax, easy readability, and easy comprehension. ',
    id: 4,
    img1: icon1,
    img2: icon2,
    text1: '3 Months',
    text2: 'Twice a week',
    path: "/Pythondata",

  },
  {
    image: Datascience,
    title:'Crypto Trading Using Algorithms',
    text: 'Python is one of the most popular programming languages for high-level data processing, due to its simple syntax, easy readability, and easy comprehension. ',
    id: 5,
    img1: icon1,
    img2: icon2,
    text1: '3 Months',
    text2: 'Twice a week',
    path: "/cryptotrading",

  },
  {
    image: Datascience,
    title:'Currency Trading Using Algorithms',
    text: 'Python is one of the most popular programming languages for high-level data processing, due to its simple syntax, easy readability, and easy comprehension. ',
    id: 6,
    img1: icon1,
    img2: icon2,
    text1: '3 Months',
    text2: 'Twice a week',
    path: "/currencytrading",

  },

 
];


const AllCourses = () => {
  return (
    <>
    <Navbar/>
    <div className='ksvesity__allcourses'>
      <div className='ksvesity__allcourses-container'>
        <h1>Explore Our Courses</h1>
        <h5>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</h5>
        <div className='ksvesity__allcourses-container_courses'>
          {courses.map((course)=>{
            return(
              <div key={course.id} className='ksvesity__allcourses-container_courses-details'>
                <div className='image'>
                  <img src={course.image} alt='PIC'/>
                </div>
                <div className='text'>
                  <h2><Link to={course.path}>{course.title}</Link></h2>
                  <p>{course.text}</p>
                  <div className='text-duration'>
                    <div className='one'>
                      <img src={course.img1} alt='img1'/>
                      <h6>{course.text1}</h6>
                    </div>
                    <div className='two'>
                      <img src={course.img2} alt='img2'/>
                      <h6>{course.text2}</h6>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default AllCourses
