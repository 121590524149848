import './App.css';
import Navbar from './Navbar/Navbar';
import Banner from './Banner/Banner';
import Banner2 from './Banner2/Banner2';
import Kidzone from './Kidzone/Kidzone';
import Grow from './Grow/Grow';
import Funfact from './Funfact/Funfact';
import Faq from './Faq/Faq';
import Newsletter from './Newsletter/Newsletter';
import Footer from './Footer/Footer';
import Section3 from './section3/section3';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Banner2 />
      <Kidzone />
      <Section3/>
      <Grow />
      <Funfact />
      <Faq />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default App;
