import React from 'react'
import './Cards.css'

const Cards = (props) => {
  return (
    <div className='ksvesity__cards'>
        <div className='ksvesity__cards-container'>
            <p>{props.text}</p>
            <div className='details'>
                <img src={props.image} alt='props'/>
                <div>
                    <h4>{props.name}</h4>
                    <p>{props.position}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cards
