import React from 'react'
import './Banner2.css'
import Skills from '../Assets/home.jpg'
import icon from '../Assets/Vector.png'
import { Link } from 'react-router-dom'

const Banner2 = () => {
  return (
    <div className='ksvesity__banner2' id='courses'>
      <div className='ksvesity__banner2-container'>
        <div className='ksvesity__banner2-container_img'>
            <img src={Skills} alt='SKILLS' className='skill'/>
        </div>
        <div className='ksvesity__banner2-container_details'>
            <h2>Unlock Your Potential, Master Finance Skills, and Grow with Us!</h2>
            <p>Unleash Your Potential: Gain the Skills and Expertise to Excel as a Finance Professional in Today's Global Market.</p>
            <div className='ksvesity__banner2-container_details-skills'>
                <div className='item'>
                    <img src={icon} alt='icon' />
                    <h3>Expert instruction</h3>
                </div>
                <div className='item'>
                    <img src={icon} alt='icon'/>
                    <h3>Community Events</h3>
                </div>
                <div className='item'>
                    <img src={icon} alt='icon'/>
                    <h3>Pratical Learning</h3>
                </div>
                <div className='item'>
                    <img src={icon} alt='icon'/>
                    <h3>Mentorship</h3>
                </div>
            </div>
            <Link to='/all-courses'>
            <button className='ksvesity__banner2-container_details-button'>Browse All Courses</button>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Banner2

