import React from 'react'
import './Funfact.css'

const Funfact = () => {
  return (
    <div className='ksvesity__funfact'>
        <div className='ksvesity__funfact-container'>
            <h5>Fun Facts</h5>
            <h1 className='h1'>Our Fun Facts</h1>
            <div className='ksvesity__funfact-container_items'>
                <div className='ksvesity__funfact-container_items-one'>
                    <h1>98%</h1>
                    <p>Completion Rate</p>
                </div>
                <div className='ksvesity__funfact-container_items-two'>
                    <h1>1000+</h1>
                    <p>Successful Graduates</p>
                </div>
                <div className='ksvesity__funfact-container_items-three'>
                    <h1>10+</h1>
                    <p>Partners</p>
                </div>
                <div className='ksvesity__funfact-container_items-four'>
                    <h1>2000+</h1>
                    <p>Success Stories</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Funfact
