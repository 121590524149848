import React from 'react'
import './Kidzonepage.css'
import Navbar from '../../Navbar/Navbar'
import KidZoneBanner from '../../PagesComponents/KidZoneBanner/KidZoneBanner'
import KidZoneBanner2 from '../../PagesComponents/KidZoneBanner2/KidZoneBanner2'
import KidZoneEnroll from '../../PagesComponents/KidZoneEnroll/KidZoneEnroll'
import KidZoneSection from '../../PagesComponents/KidZoneSection/KidZoneSection'
import KidZoneTestimony from '../../PagesComponents/KidZoneTestimony/KidZoneTestimony'
import Newsletter from '../../Newsletter/Newsletter'
import Footer from '../../Footer/Footer'
import Girl from '../../Assets/little-girl.png'

const data = 
  {
    image: Girl,
    H4: 'WHY CHOOSE US',
    H2: 'BUILD DATA SKILLS WITH EXPERTS ANY TIME, ANYWHERE',
    P: 'We understand better that data online-based learning can make a significant change to reach students from all over the UK ! Giving options to learn better always can offer the best option for your career!',
    item1 : 'Skilled Trainers',
    item2: 'Affordable Courses',
    item3: 'Efficient & Flexible',
    item4: 'Lifetime Access',
    button: 'Enroll Now'

  }


const Kidzonepage = () => {
  
  return (
    <div>
      <Navbar />
      <div>
        <KidZoneBanner />
        <KidZoneBanner2 />
        <KidZoneEnroll {...data}/>
        <KidZoneSection />
        <KidZoneTestimony />
        <Newsletter />
        <Footer />
      </div>
    </div>
  )
}

export default Kidzonepage