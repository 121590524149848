import React, { useEffect, useState } from 'react'
import './Testimony.css'
import Man from '../../Assets/man.png'
import Woman from '../../Assets/woman.png'
import Cards from '../Cards/Cards'
import {FaRegDotCircle} from 'react-icons/fa'
import Testimonyy from '../../Assets/testimony.png'


const Testimony = () => {
    const [firstTest, setFirstTest] = useState(true)
    const [secondTest,setSecondTest] = useState(false)

    useEffect(()=>{
        const timeout = setTimeout(()=>{
            setFirstTest(prev=> !prev)
            setSecondTest(prev=> !prev)
        }, 3000)
        
        return ()=>{
            clearTimeout(timeout)
        }
    },[firstTest,secondTest])

  return (
    <div className='ksvesity__testimony'>
        <div className='ksvesity__testimony-container'>
            {firstTest && 
                <div className='ksvesity__testimony-container-cards'>
                    <Cards 
                    text = 'My experiences of the KSV as a data enthusiast, volunteer and mentor has been a positive one. My progression route was made possible by the learning, knowledge and nurturing environment from the organisation. They worked with me to identify me strengths and how my skills could be used in my personal life, in my work and for my community.'
                    image = {Man}
                    name = 'Toni O.'
                    position ='Full-stack developer'
                    />
                    <Cards 
                    text = 'I enrolled at KSV on the Introduction to Data Science for Business in 2021. I was given the opportunity to partake in the DataKirk & Public Health Scotland Mentoring programme. I used the learning from the mentoring to enhance my CV, interview skills and employability. I am now gainfully employed. I want to appreciate Datakirk for this opportunity, the information that I have been previledged to get from Tony has really been overwhelming. I am more than grateful.'
                    image = {Woman}
                    name = 'Bisi'
                    position ='KSV Alumnus'
                    />
                </div>
            }
            {secondTest && 
                <div className='ksvesity__testimony-container-cards'>
                    <Cards 
                    text = 'I enrolled at KSV on the Introduction to Data Science for Business in 2021. I was given the opportunity to partake in the DataKirk & Public Health Scotland Mentoring programme. I used the learning from the mentoring to enhance my CV, interview skills and employability. I am now gainfully employed. I want to appreciate Datakirk for this opportunity, the information that I have been previledged to get from Tony has really been overwhelming. I am more than grateful.'
                    image = {Woman}
                    name = 'Bisi'
                    position ='KSV Alumnus'
                    />
                    <Cards 
                    text = 'My experiences of the KSV as a data enthusiast, volunteer and mentor has been a positive one. My progression route was made possible by the learning, knowledge and nurturing environment from the organisation. They worked with me to identify me strengths and how my skills could be used in my personal life, in my work and for my community.'
                    image = {Man}
                    name = 'Toni O.'
                    position ='Full-stack developer'
                    />
                </div>
            }
            <div className='ksvesity__testimony-container-circle'>
                <FaRegDotCircle color={firstTest ? '0000FF' : '000'} size = '16' className='one' />
                <FaRegDotCircle color={secondTest ? '0000FF' : '000'} size = '16' className='two'/>
            </div>
            <div className='ksvesity__testimony-container-image'>
                <img src={Testimonyy} alt='Testimony' />
            </div>
        </div>
    </div>
  )
}

export default Testimony