import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Course from './Course'
import Course1 from '../Assets/fintech-course1.png';
import Course2 from '../Assets/fintech-course2.png';
import Course3 from '../Assets/fintech-course3.png';



function Section3() {
  const courses = [
    {
      image: Course1,
      author: 'KSV',
      title: 'Python for Finance (Trading)',
      description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.",
      students: 2
    },
    {
      image: Course2,
      author: 'KSV',
      title: 'Algorithmic Trading',
      description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.",
      students: 2
    },
    {
      image: Course3,
      author: 'KSV',
      title: 'Basic Knowledge Python for Finance',
      description: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.",
      lessons: 7,
      students: 2
    }
  ];

  return (
    <div style={{backgroundColor: '#F5F7FA', paddingBottom: '1rem'}}>
      <Container>
        <div>
          <h2 className='display-4 text-center'  style={{ fontFamily: 'Nunito', fontSize: '36px', paddingTop: '50px', fontWeight:'800'}}>Our Fintech Courses</h2>
          <p className='lead text-center ' style={{ fontFamily: 'Nunito', fontSize: '16px',  fontWeight:'400'}}>
            Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!
          </p>
        </div>
        <Row className='d-flex align-items-stretch' style={{marginTop:'4.5rem'}}>
          {courses.map((course, index) => (
            <Col md={4} key={index} className='mb-4'>
              <Course
                image={course.image}
                author={course.author}
                title={course.title}
                description={course.description}
                lessons={course.lessons}
                students={course.students}
              />
            </Col>
          ))}
        </Row>
        <p className='text-center '>
          Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion <span style={{fontWeight: '800'}}>Register Now.</span>
        </p>
      </Container>
    </div>
  );
}

export default Section3;
