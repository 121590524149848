import React from 'react'
import './ContactUs.css'
import Navbar from '../../Navbar/Navbar'
import ContactUsInfo from '../../PagesComponents/ContactUsInfo/ContactUsInfo'
import ContactUsEnroll from '../../PagesComponents/ContactUsEnroll/ContactUsEnroll'
import Footer from '../../Footer/Footer'

const ContactUs = () => {
  return (
    <div>
        <Navbar />
        <div>
            <ContactUsInfo />
            <ContactUsEnroll />
            <Footer />
        </div>
    </div>
  )
}

export default ContactUs