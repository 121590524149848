import React from 'react'
import './Grow.css'

const Grow = () => {
  return (
    <div className='ksvesity__grow'>
        <div className='ksvesity__grow-container'>
            <h1>Grow with KSV</h1>
            <p className='p1'>We are working to change the face of Africa’s future talent pool by increasing the representation of the disadvatanged groups in the tech economy.</p>
            <div className='ksvesity__grow-container_items'>
                <div className='ksvesity__grow-container_items-one'>
                    <h2>In-Demand Tech Skills</h2>
                    <p>Let’s accelerate your journey into tech with our immersive training program. We will help you acquire the hottest skills in high demand in the job market.</p>
                </div>
                <div className='ksvesity__grow-container_items-two'>
                    <h2>Conducive environment</h2>
                    <p>With us, you learn with comfort. You learn in a conducive environment with all basic amenities at your disposal.</p>
                </div>
                <div className='ksvesity__grow-container_items-three'> 
                    <h2>Flexible Calendars</h2>
                    <p>We offer both weekday and weekend classes both in-person and online. We start at least 2 new cohorts every quarter. So, Whether you work full-time or not you can learn with us.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Grow
