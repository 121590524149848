import React from 'react'
import './KidZoneTestimony.css'

const KidZoneTestimony = () => {
  return (
    <div className='ksvesity__kidzonetestimony'>
        <div className='ksvesity__kidzonetestimony-container'>
            <p>I joined the KSedge Community in 2019 when I enrolled in the Introduction to Data Visualisation training. This training gave me an understanding of how of data visualisation can be biased and manipulated to suit a purpose. The training was a reminder of being reflective of information given before us. As a result of the training, I'm more aware and question any data before me.</p>
            <h4>Juliet M.</h4>
            <h6>Data Enthusiast</h6>
        </div>
    </div>
  )
}

export default KidZoneTestimony