import React, { useState } from 'react';
import './Navbar.css';
import logo from '../Assets/logo.png';
import { AiFillCloseCircle, AiOutlineMenuFold } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const MenuItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <li className='menu-item ' onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
      <a href='/'>{title}</a>
      {isOpen && <div className='dropdown'>{children}</div>}
    </li>
  );
};

const Column = ({ title, children }) => {
  return (
    <div className='column'>
      <h2>{title}</h2>
      <ul>{children}</ul>
    </div>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className='ksvesity__navbar'>
      <div className='ksvesity__navbar-container'>
        <div className='ksvesity__navbar-container_image'>
          <a href='/'><img src={logo} alt='LOGO' className='logo' /></a>
        </div>
        <ul className='ksvesity__navbar-container_content-container'>
          <MenuItem title='Courses'>
            <Column title='Courses'>
              <p>
                We offer free and paid courses to equip everyone, regardless of their background, with the right data skills to drive informed decisions in the modern world.
              </p>
            </Column>
            <Column title='Technology'>
              <hr />
              <li>Data Analyst</li>
              <li>Cybersecurity</li>
              <li>Cloud Computing</li>
              <li>Artificial Intelligence</li>
              <li>DevOps</li>
            </Column>
            <Column title='Fintech'>
              <hr />
              <li>Blockchain</li>
              <li>Algorithmic Trading</li>
            </Column>
          </MenuItem>
          <li><Link to='/aboutus'><a href='#about-us'>About Us</a></Link></li>
          <li><Link to='/kidzonepage'><a href='#KK'>Kids Zone</a></Link></li>
        </ul>
        <div className='ksvesity__navbar-container_contact'>
          <button className='ksvesity__navbar-container_contact-signin'>Login</button>
          <button className='ksvesity__navbar-container_contact-signup'>Sign up</button>
        </div>
        <div className='ksvesity__navbar-container_menu'>
          {isOpen ? (
            <AiFillCloseCircle color='#000' size={30} onClick={toggleMenu} />
          ) : (
            <AiOutlineMenuFold color='#000' size={30} onClick={toggleMenu} />
          )}
          {isOpen && (
            <div className='ksvesity__navbar-container_menu-links slide-in-top'>
              <ul>
                <li><Link to='/all-courses'>Courses</Link></li>
                <li><Link to='/aboutus'>About Us</Link></li>
                <li><Link to='/kidzonepage'>Kids Zone</Link></li>
              </ul>
              <div className='ksvesity__navbar-container_menu-button'>
                <button className='ksvesity__navbar-container_menu-button-login'>Login</button>
                <button className='ksvesity__navbar-container_menu-button-signup'>Sign up</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;


