import React from 'react'
import './CurrencyTrading.css'
import Navbar from '../../Navbar/Navbar'
import PagesBanner from '../../PagesComponents/PagesBanner/PagesBanner'
import PythonS from '../../Assets/PythonS.png'
import CourseSummary from '../../PagesComponents/CourseSummary/CourseSummary'
import FaqCourses from '../../PagesComponents/faq/FaqCourses'
import Expectation from '../../PagesComponents/Expectation/Expectation'
import Testimony from '../../PagesComponents/Testimony/Testimony'
import Application from '../../PagesComponents/Application/Application'
import Newsletter from '../../Newsletter/Newsletter'
import Footer from '../../Footer/Footer'

const data =
{
  title: 'Currency Trading Using Algorithms',
  text: 'Acquire the skills and knowledge needed to build a complete web development project from scratch to finish. Our Full-Stack Developer’s Program is a 6 Months immersive training and working program.',
  id: 1,
  image: PythonS 
}

const faqAnswer =
{
  answer1:'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
  answer2:'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program',
  answer3:'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program', 
  answer4:'Our program is an on-site training program within our comfortable and conducive learning rooms. However, if you are interested in an online program'
}

const CurrencyTrading = () => {
  return (
    <div>
        <Navbar />
        <div>
          <PagesBanner {...data} /> 
          <CourseSummary />
          <FaqCourses {...faqAnswer}/>
          <Expectation />
          <Testimony />
          <Application text='Currency Trading Using Algorithms'/>
          <Newsletter />
          <Footer />
        </div>
    </div>
  )
}

export default CurrencyTrading