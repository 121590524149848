import React from 'react'
import './AboutUsVolunteer.css'

const AboutUsVolunteer = () => {
  return (
    <div className='ksvesity__aboutusvolunteer'>
        <div className='ksvesity__aboutusvolunteer-container'>
            <h5>Looking to create an Impact</h5>
            <h2>Volunteer with Us at the KSedge</h2>
            <p>Through volunteering, individuals, data and STEM professionals play an important part in supporting our vision at the KSedge. We recruit vlunteers who share our passion for closing both the attainment gap and data divide.</p>
            <button >
                Reach out to us
            </button>
        </div>
    </div>
  )
}

export default AboutUsVolunteer