import React , {useState} from 'react'
import './FaqCourses.css'
import {MdKeyboardArrowDown} from 'react-icons/md'

const FaqCourses = ({answer1,answer2,answer3,answer4}) => {
    const faqData = [
        {
          title: '• About this Course',
          text: answer1,
          id: 1,
          click: 'click here'
        },
        {
          title: '• What you will Learn',
          text: answer2,
          id: 2,
          click: 'click here'
        },
        {
          title: '• Prerequisites',
          text: answer3,
          id: 3,
          click: 'click here'
        },
        {
          title: '• Course Outline',
          text: answer4,
          id: 4,
          click: 'click here'
        },

      ];
    
      const [data, setData] = useState(faqData);
    
      const openAnswer = (id) => {
        setData(prevState =>
          prevState.map(faq =>
            faq.id === id ? { ...faq, on: !faq.on } : faq
          )
        );
      };
    
      return (
        <div className='ksvesity__faqcourses'>
          <div className='ksvesity__faqcourses-container'>
            {data.map(item => (
              <div className='ksvesity__faqcourses-container-div' key={item.id}>
                <div className='ksvesity__faqcourses-container-div_item-faq'>
                  <h3 style={{ color: item.on ? 'blue' : 'black' }}>{item.title}</h3>
                  <p className={item.on ? 'ksvesity__faqcourses-container-div_item-faq-p_active' : 'ksvesity__faqcourses-container-div_item-faq-p'}>
                    {item.text} <span>{item.click}</span>
                  </p>
                </div>
                <div className='ksvesity__faqcourses-container-div_item-icon'>
                  <MdKeyboardArrowDown onClick={() => openAnswer(item.id)} style={{ color: item.on ? 'blue' : 'black' }} />
                </div>
              </div>
            ))}
                <div className='ksvesity__faqcourses-container-buttons'>
                  <button className='apply'>Apply Now</button>
                  <button className='download'>Download Syllabus</button>
                </div>
          </div>
        </div>
      );
};


export default FaqCourses