import React from 'react'
import './KidZoneEnroll.css'
import icon from '../../Assets/Vector.png'


const KidZoneEnroll = ({image, H4, H2, P, item1, item2, item3, item4, button}) => {
    return (
        <div className='ksvesity__kidzoneenroll ' id='kids-zone'>
                <div className='ksvesity__kidzoneenroll-container_item1'>
                     <div className='ksvesity__kidzoneenroll-container_item1-image'>
                        <img src={image} alt='Item' />
                    </div>
                    <div className='ksvesity__kidzoneenroll-container_item1-info'>
                        <h4>{H4}</h4>
                        <h2>{H2}</h2>
                        <p>{P}</p>
                        <div className='ksvesity__kidzoneenroll-container_item1-info-about'>
                            <div className='item'>
                                <img src={icon} alt='icon' />
                                <h3>{item1}</h3>
                            </div>
                            <div className='item'>
                                <img src={icon} alt='icon' />
                                <h3>{item2}</h3>
                            </div>
                            <div className='item'>
                                <img src={icon} alt='icon' />
                                <h3>{item3}</h3>
                            </div>
                            <div className='item'>
                                <img src={icon} alt='icon' />
                                <h3>{item4}</h3>
                            </div>
                        </div>
                        {button && <a href='#ggg'><button className='ksvesity__kidzoneenroll-container_item1-info-button'>{button}</button>
                         </a>}
                    </div>

                </div>
        </div>
    )
}

export default KidZoneEnroll