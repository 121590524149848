import React from 'react'
import './Application.css'

const Application = (props) => {
  return (
    <div className='ksvesity__application'>
        <div className='ksvesity__application-container'>
            <h1>{props.text}</h1>
            <p>The Fullstack skill gives you the leverage you need in the tech space. The program is aimed at intimating you with everything you need to become a successful developer in the job market.</p>
            <div className='ksvesity__application-container-buttons'>
                <button className='one'>Apply</button>
                <button className='two'>Download Syllabus</button>
            </div>
        </div>
    </div>
  )
}

export default Application