import React from 'react';
import BlackCircle from '../Assets/black-circle.png';
import RedCircle from '../Assets/red-circle.png';
import Aeroplane from '../Assets/aeroplane-shape.png';
import Constellation from '../Assets/constellation-shape.png';

import './Newsletter.css';

const Newsletter = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('submit');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='ksvesity__newsletter' id='newsletter'>
        <div className='ksvesity__newsletter-container'>
          <h3>Go At Your Own Pace</h3>
          <h2>Subscribe to Our Newsletter</h2>
          <h5>Don't be left out from our next event and offers</h5>
          <div className='ksvesity__newsletter-container_email'>
            <input type='email' name='email' placeholder='Enter your email address' />
            <button className='ksvesity__newsletter-container-button'>Subscribe Now</button>
          </div>
          <div className='ksvesity__newsletter-container_design1'>
            <img src={BlackCircle} className='design1-one' alt='Black Circle' />
            <img src={RedCircle} className='design1-two' alt='Red Circle' />
          </div>
          <div className='ksvesity__newsletter-container_design2'>
            <img src={Constellation} className='design2-one' alt='Constellation' />
            <img src={Aeroplane} className='design2-two' alt='Aeroplane' />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Newsletter;






// import React from 'react'
// import BlackCircle from '../Assets/black-circle.png'
// import RedCircle from '../Assets/red-circle.png'
// import Aeroplane from '../Assets/aeroplane-shape.png'
// import Constellation from '../Assets/constellation-shape.png'

// import './Newsletter.css'

// const Newsletter = () => {
//   return (
//     <form onSubmit={()=>console.log('submit')}>
//         <div className='ksvesity__newsletter' id='newsletter'>
//             <div className='ksvesity__newsletter-container'>
//                 <h3>Go At Your Own Pace</h3>
//                 <h2>Subscribe to Our Newsletter</h2>
//                 <h5>Don't be left out from our next event and offers</h5>
//                 <div className='ksvesity__newsletter-container_email'>
//                     <input type='email' name='email' value={''}  placeholder='Enter your email address'/>
//                     <button className='ksvesity__newsletter-container-button'>Subscribe Now</button>
//                 </div>
//                 <div className='ksvesity__newsletter-container_design1'>
//                     <img src={BlackCircle} className='design1-one' />
//                     <img src={RedCircle} className='design1-two' />
//                 </div>
//                 <div className='ksvesity__newsletter-container_design2'>
//                     <img src={Constellation} className='design2-one'/>
//                     <img src={Aeroplane} className='design2-two' />
//                 </div>
//             </div>
//          </div>
//     </form>

//   )
// }

// export default Newsletter
